import styled from 'styled-components';

export const Logo = styled.img`
  /* margin-left: 8px;

  @media (max-width: 1439px) {
    width: 80px;
    height: 20px;
  }

  @media (max-width: 767px) {
    max-width: 75px;
    max-height: 18px;
  } */
`;
